/* eslint-disable require-atomic-updates */
import {notification} from 'antd';
import axios from 'axios';
import {omit, pick} from 'lodash';
import {stringifyUrl} from 'query-string';

import {DOWNLOAD_HELP_DOC} from '../../api/common';
import {storageKeys} from '../../constants/storageKeys';
import {BASE_URL} from '../../constants/url';
import {checkTokenValid, logoutHandle} from '../history';

const isDevEnv = process.env.NODE_ENV === 'development';

const TIMEOUT = 2 * 60 * 1000;
const UNAUTH_MESSAGE = '登录失效, 请重新登录';
export const ERROR_MESSAGE = '请求错误，请检查网络或联系系统管理员';
const notNeedFreshTokenApi = [
  '/auth/login',
  '/auth/logout',
  '/auth/refreshToken',
  '/code',
  '/user/setting/getIndexSysSetting',
  '/auth/rootLogin',
];

const getToken = () => {
  const token = window.localStorage.getItem(storageKeys.TOKEN);
  return token;
};

// const isSamePage = (() => {
//   let prePagePath = '';
//   return () => {
//     const curPagePath = getCurVisitPath();
//     if (prePagePath === curPagePath) {
//       return true;
//     }
//     prePagePath = curPagePath;
//     return false;
//   };
// })();

let cancelToken = axios.CancelToken;
let source = cancelToken.source();

/**
 * @type {import('./types').RequestInstance}
 */
const request = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT,
});

request.interceptors.request.use(
  async (config) => {
    if (!notNeedFreshTokenApi.includes(config.url.replace(/\?\S*/g, ''))) {
      config.cancelToken = source.token;
      const res = await checkTokenValid();
      // 不存在token 取消所有请求
      if (!res) {
        source.cancel();
        cancelToken = axios.CancelToken;
        source = cancelToken.source;
      }
    }

    config.headers.Authorization = getToken();

    if (!isDevEnv && ['put', 'delete'].includes(config.method.toLowerCase())) {
      const OverrideMethod = config.method.toUpperCase();
      config.method = 'post';
      config.headers['X-HTTP-Method-Override'] = OverrideMethod;
    }

    if (config?.url?.includes('/stxz')) {
      // 数图接口不需要前缀
      config.baseURL = '';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    const {data} = response;

    if (
      [DOWNLOAD_HELP_DOC].includes(response.config.url) ||
      response.headers['content-disposition']?.includes('filename')
    ) {
      return response;
    }

    if (![0, 200].includes(data.code)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: data.code,
        ...data,
        data,
      });
    }

    return response.data;
  },

  (error) => {
    /** @type {import('axios').AxiosResponse} */
    const {data = {}, status} = error.response ?? {};
    const {msg} = data;

    switch (status) {
      case 401:
        notification.error({description: msg ?? UNAUTH_MESSAGE});
        logoutHandle(false);
        source.cancel();
        cancelToken = axios.CancelToken;
        source = cancelToken.source;
        break;

      case 403:
        notification.error({description: msg ?? UNAUTH_MESSAGE});
        break;

      case 404:
        notification.error({description: msg ?? ERROR_MESSAGE});
        break;

      case 500:
        notification.error({description: msg ?? ERROR_MESSAGE});
        break;

      /* case 512:
        // 抛出至页面，根据原型要求按不同情况处理报错
        return Promise.reject(error.response); */

      default:
        // 这里包含网络问题请求失败，error.response为undefined
        return Promise.reject(error.response);
    }

    return Promise.reject(error.response);
  }
);

export const getFetcher = (url, {arg: query = {}, signal} = {}) => {
  const _url = `${url}${query?._pathParam ? `/${query?._pathParam}` : ''}`;
  return request.get(
    stringifyUrl({
      url: _url,
      query: omit(query, ['_pathParam', '_bodyParams']),
    }),
    {signal, params: query?._bodyParams}
  );
};

export const postFetcher = (urlRaw, {arg, signal}) => {
  const _url = `${urlRaw}${arg?._pathParam ? `/${arg?._pathParam}` : ''}`;
  const url = stringifyUrl({
    url: _url,
    query: pick(arg, ['_queryParams'])?._queryParams,
  });
  if (arg) {
    delete arg._pathParam;
    delete arg._queryParams;
  }
  return request.post(url, arg, {signal});
};

export default request;
