import {css} from '@emotion/react';
import {Button, Layout as ALayout, List, Skeleton} from 'antd';
import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useLocalStorage} from 'react-use';

import {useInitTooltipAtom} from '../../atoms/tooltip';
import Filter from '../../components/Filter';
import IconFont from '../../components/IconFont';
import {layoutConfig} from '../../constants/layout';
import {storageKeys} from '../../constants/storageKeys';
import {overflowScrollBar} from '../../global.css';
import {logoutHandle} from '../../helpers/history';
import useAuthMenuTree from '../../hooks/useAuthMenuTree';
import {useMenuCodeInRequest} from '../../hooks/useMenuCodeInRequest';
import {useToken} from '../../hooks/useToken';
import Header from './components/Header';
import Menus from './components/Menus';
import NoticeModal from './components/NoticeModal';
import {PermChangeModal} from './components/PermChangeModal';
import {contentCSS} from './layout.css';

export default function Layout({children, ...rest}) {
  const {token} = useToken();

  const {DEFAULT_COLLAPSED} = layoutConfig.sidebar;

  const [showPage, setShowPage] = useState(false);
  const [collapse, setCollapse] = useLocalStorage(
    storageKeys.COLLAPSED,
    DEFAULT_COLLAPSED
  );
  const [hack4MenuBugOnCollapse, setHack4MenuBugOnCollapse] =
    useState(collapse);
  const [, setOpenKeys] = useLocalStorage(storageKeys.OPEN_MENU_KEYS);

  // const [, setPageRouteAtom] = useAtom(pageRouteAtom);
  const [, getAuthMenuTreeHandle] = useAuthMenuTree();

  const [breadcrumbsMenu, setBreadcrumbsMenu] = useLocalStorage(
    storageKeys.BREAD_KEYS
  );

  useEffect(() => {
    if (token) {
      // 获取菜单路由
      getAuthMenuTreeHandle();

      // 获取路由组件列表
      /* getPageRouters().then((res) => {
        const pageRoutersList = res?.data || [];
        setPageRouteAtom(pageRoutersList);
      }); */
    } else {
      logoutHandle(false);
    }
  }, [getAuthMenuTreeHandle, /* setPageRouteAtom, */ token]);

  useInitTooltipAtom();

  useMenuCodeInRequest();

  return (
    <ALayout {...rest}>
      {layoutConfig.header.SHOW_HEADER && (
        <Header breadcrumbsMenu={breadcrumbsMenu} collapse={collapse} />
      )}
      <ALayout
        className={hack4MenuBugOnCollapse ? '' : 'hidePopupMenu'}
        id='mainContent'
      >
        <ALayout.Sider
          collapsible
          collapsed={collapse}
          css={css`
            background: unset;
            .ant-layout-sider-children {
              background: #f7f8fa;
            }
            padding: 0;
            background-color: #f7f8fa;
            border-right: 1px solid rgba(151, 151, 151, 0.05);
            height: 100%;
            > .ant-layout-sider-children {
              padding: 0 8px 0 8px;
              overflow: hidden auto;
              ${overflowScrollBar};
            }
          `}
          trigger={null}
          width={205}
        >
          <Menus
            collapse={collapse}
            setBreadcrumbsMenu={setBreadcrumbsMenu}
            setShowPage={setShowPage}
          />
          <div
            css={css`
              position: absolute;
              right: -13px;
              top: 16px;
              &:hover {
                .btnHover {
                  display: block;
                }
              }
            `}
          >
            <Button
              css={css`
                width: 20px;
                height: 20px;
                border-radius: 50%;
                transform: rotate(${collapse ? '0deg' : '180deg'});
              `}
              icon={<IconFont type='icon-arrow-right' />}
              type='default'
              onClick={() => {
                const nextState = !collapse;
                if (nextState) {
                  setOpenKeys([]);
                }
                setCollapse(nextState);
                if (nextState) {
                  // remove hidePopupMenu class on layout after submenu's animation
                  setTimeout(() => {
                    setHack4MenuBugOnCollapse(nextState);
                  }, 500);
                } else {
                  setHack4MenuBugOnCollapse(nextState);
                }
              }}
            />
            <div
              className='btnHover'
              css={css`
                position: absolute;
                padding: 2px 5px;
                line-height: 14px;
                color: rgba(255, 255, 255, 0.8);
                background-color: rgba(22, 35, 78, 0.8);
                font-size: 10px;
                width: 70px;
                left: -30px;
                border-radius: 1px;
                display: none;
              `}
            >
              {collapse ? '展开侧边栏' : '收起侧边栏'}
            </div>
          </div>
        </ALayout.Sider>

        <ALayout.Content css={contentCSS}>
          {showPage ? (
            <div
              css={css`
                flex: 1;
                height: 100%;
                overflow: hidden;
                padding: 12px;
              `}
            >
              <Outlet />
            </div>
          ) : (
            <List
              css={css`
                flex: 1;
              `}
              dataSource={[1, 2, 3, 4]}
              itemLayout='vertical'
              renderItem={(item) => (
                <List.Item>
                  <Skeleton active title={{width: '100%'}} />
                </List.Item>
              )}
              size='large'
            />
          )}
        </ALayout.Content>
      </ALayout>

      <NoticeModal />

      <PermChangeModal />

      <Filter />
    </ALayout>
  );
}
