// 推送用户方式类型
export const PUSH_USER_WAY_TYPE = {
  // 公开
  public: 1,
  // 用户
  user: 2,
  // 标签
  tag: 3,
  // 地区
  area: 4,
};

// 用户类型
export const USER_TYPE = {
  // 厨房用户
  kitchen: 1,
  // 餐厅用户
  restaurant: 2,
};

export const ENABLE_FLAG = {
  // 启用
  enable: 1,
  // 禁用
  disable: 0,
};
