import {images} from '../assets';

export const layoutConfig = {
  sidebar: {
    TITLE: '',
    LOGO: images.logoOnly,
    SHOW_LOGO: true,
    DEFAULT_COLLAPSED: false,
  },
  header: {
    SHOW_HEADER: true,
    DEFAULT_AVATAR: 'https://avatars.dicebear.com/api/pixel-art/qhlabteam.svg',
  },
  footer: {
    SHOW_FOOTER: true,
  },
  modal: {
    maxHeight: 720,
  },
};

export const triggerType = ['hover'];
